import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { BatchModel, ORDERS_TAB, OrderFilterModel, OrderModel } from 'model/Entities';
import {
  ConfirmationDialog,
  CustomForm,
  CustomIconButton,
  CustomModal,
  CustomTable,
  CustomTextField,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'components';
import CustomDateQuickFilter, { QuickFilterProps } from 'components/CustomDateQuickFilter';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  autoBatchOrders,
  deleteOrder,
  getOrders,
  markAsShippedByOrderId,
  resolveStopCheck,
  restoreOrder,
  stopCheck,
  updateShippingAddress,
} from 'api/order';
import { autoCreateBatches, createBatch, validateOrders } from 'api/batch';
import { batch_schema, shipping_address_schema, stop_check_schema } from 'model/schema';
import { capitalizeWord, formatDate, getDateRange } from 'utils';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'model/interface';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomFilter from 'components/CustomFilter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagOrderModal from 'screens/StopCheck/components/FlagOrderModal';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import OrderPresetFilter from './OrderPresetFilter';
import OrderRow from './OrderRow';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SearchBar from 'components/SearchBar';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { tokens } from 'context/theme.context';
import { updateOrderFilter } from 'api/order-filter';
import { useSnackbar } from 'notistack';

type Props = {
  status: ORDERS_TAB;
  setTotalCount?: (value: number) => void;
  pendingTableRef?: any;
  batchedTableRef?: any;
  resolvedTableRef?: any;
  stopCheckTableRef?: any;
  deletedTableRef?: any;
};

const OrdersTable = forwardRef((props: Props, ref) => {
  const {
    status,
    setTotalCount,
    pendingTableRef,
    batchedTableRef,
    resolvedTableRef,
    stopCheckTableRef,
    deletedTableRef,
  } = props;
  useImperativeHandle(ref, () => ({ refreshTable }));

  const tableRef: any = useRef();
  const filterRef: any = useRef();
  const colors = tokens();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<OrderModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingCreateBatch, setLoadingCreateBatch] = useState(false);
  const [loadingEditShippingAddress, setLoadingEditShippingAddress] = useState(false);
  const [loadingEditRemarks, setLoadingEditRemarks] = useState(false);

  const [openViewOrders, setOpenViewOrders] = useState(false);
  const [openCreateBatch, setOpenCreateBatch] = useState(false);
  const [validatingOrders, setValidatingOrders] = useState(false);
  const [invalidOrders, setInvalidOrders] = useState<number[]>([]);
  const [validOrders, setValidOrders] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [errors, setErrors] = useState<any[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<GridSelectionModel>([]);
  const [selectedOrdersDetails, setSelectedOrdersDetails] = useState<OrderModel[]>([]);
  const [otherFilter, setOtherFilter] = useState<any>({});
  const [orderDateRangeFilter, setOrderDateRangeFilter] = useState<QuickFilterProps>();
  const [shipByDateRangeFilter, setShipByDateRangeFilter] = useState<QuickFilterProps>();
  const [shippingCountryFilter, setShippingCountryFilter] = useState<string[]>([]);
  const [fraudulentStatusFilter, setFraudulentStatusFilter] = useState<string[]>([]);
  const [stationFilter, setStationFilter] = useState<string[]>([]);
  const [skuSearch, setSkuSearch] = useState<string>();
  const [tagSearch, setTagSearch] = useState<string>();
  const [autoSelect, setAutoSelect] = useState<string>('');
  const [activeFilter, setActiveFilter] = useState<OrderFilterModel>();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRestore, setOpenConfirmRestore] = useState(false);
  const [openEditShippingAddress, setOpenEditShippingAddress] = useState(false);
  const [openEditRemarks, setOpenEditRemarks] = useState(false);
  const [openConfirmResolve, setOpenConfirmResolve] = useState(false);
  const [openSuccessResolve, setOpenSuccessResolve] = useState(false);
  const [openConfirmResolveShipping, setOpenConfirmResolveShipping] = useState(false);
  const [loadingShipConfirm, setLoadingShipConfirm] = useState(false);

  const [openStopCheck, setOpenStopCheck] = useState(false);
  const [selectedOrderForAction, setSelectedOrderForAction] = useState<OrderModel>();
  const [errorEditShippingAddress, setErrorEditShippingAddress] = useState<string>();

  // Auto batching
  const [openViewAutoBatchOrders, setOpenViewAutoBatchOrders] = useState(false);
  const [autoBatchLoading, setAutoBatchLoading] = useState(false);
  const [autoBatchConfirmLoading, setAutoBatchConfirmLoading] = useState(false);
  const [autoBatchOrderCount, setAutoBatchOrderCount] = useState(10);
  const [batches, setBatches] = useState<BatchModel[]>([]);

  const getAllOrders = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoading(true);
      try {
        query.status = status;
        const res = await getOrders(query);
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
        setTotalCount && setTotalCount(res.data.count);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    fetchData();
  };

  const createOrderBatch = async (data: any) => {
    try {
      setLoadingCreateBatch(true);
      await createBatch({ validOrders, invalidOrders, errorMessage }, data.batch_name);
      enqueueSnackbar(`Batch successfully created!`, { variant: 'success' });
      setOpenCreateBatch(false);
      setOpenViewOrders(false);
      refreshTable();
      //refresh batched table
      if (batchedTableRef) batchedTableRef.current?.refreshTable();
    } catch (error) {
      enqueueSnackbar(`Batch creation failed!`, { variant: 'error' });
    } finally {
      setLoadingCreateBatch(false);
    }
  };

  const handleAutoCreateBatches = async () => {
    try {
      setAutoBatchConfirmLoading(true);
      const data = batches.map((batch) => {
        const orders = batch.orders.map((order) => order.order_name);
        return { batch_name: batch.batch_name, orders };
      });
      await autoCreateBatches(data);
      enqueueSnackbar(`Batch successfully created!`, { variant: 'success' });
      setOpenViewAutoBatchOrders(false);
      refreshTable();
    } catch (error) {
      enqueueSnackbar(`Please try again!`, { variant: 'error' });
    } finally {
      setAutoBatchConfirmLoading(false);
    }
  };

  const refreshTable = () => {
    tableRef.current.refreshData();
    tableRef.current.clearSelected();
    setSelectedOrders([]);
  };

  const autoSelectOrders = () => {
    if (parseInt(autoSelect) > 0) {
      const count = parseInt(autoSelect);
      const filteredRows = rows.filter((row) => !row.batch_id && row.dear_order_number);
      if (filteredRows.length <= count) {
        setSelectedOrders(filteredRows.map((row) => row.order_name));
      } else {
        setSelectedOrders(Array.from(Array(count).keys()).map((index) => filteredRows[index].order_name));
      }
    } else {
      setSelectedOrders([]);
    }
  };

  // Table Columns
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'order_name',
        headerName: 'Order No.',
        flex: 1,
        renderCell: ({ value, row }) => (
          <Box display="flex" gap="10px">
            <Typography sx={{ textDecoration: row.current_station === 'cancelled' ? 'line-through' : undefined }}>
              {value}
            </Typography>
            {row.stop_check_flag && status === ORDERS_TAB.BATCHED && (
              <Tooltip title="Stop Check" arrow placement="right">
                <Chip
                  label={<PanToolOutlinedIcon sx={{ fontSize: '15px', mb: '-3px' }} />}
                  color="error"
                  size="small"
                />
              </Tooltip>
            )}
            <Box display="flex" gap="5px">
              {row.dear_pick_status && status === ORDERS_TAB.PENDING && (
                <Tooltip title="Autorised Pick" arrow placement="top">
                  <Chip
                    label={<ShoppingCartCheckoutOutlinedIcon sx={{ fontSize: '15px', mb: '-3px' }} />}
                    color="success"
                    size="small"
                  />
                </Tooltip>
              )}
              {row.dear_pack_status && status === ORDERS_TAB.PENDING && (
                <Tooltip title="Autorised Pack" arrow placement="top">
                  <Chip
                    label={<ArchiveOutlinedIcon sx={{ fontSize: '15px', mb: '-3px' }} />}
                    color="warning"
                    size="small"
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        ),
      },
      {
        field: 'batch_id',
        headerName: 'Batch',
        flex: 1.5,
        renderCell: ({ value, row }) => (
          <Typography>
            {value} - {row.batch?.batch_name}
          </Typography>
        ),
      },
      {
        field: 'dear_order_number',
        headerName: 'Dear Number',
        flex: 1,
      },
      {
        field: 'order_date',
        headerName: 'Order Date',
        flex: 1,
        renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
      },
      {
        field: 'ship_by',
        headerName: 'Ship By',
        flex: 1,
        renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
      },
      {
        field: 'line_items',
        headerName: 'SKU',
        flex: 1.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
            {row.line_items.map((item: any, index: number) => (
              <Typography key={index}>{item.sku}</Typography>
            ))}
          </Box>
        ),
      },
      {
        field: 'shipping_country',
        headerName: 'Shipping Country',
        flex: 1,
      },
      {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        flex: 1,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        flex: 1,
        sortable: false,
      },
      {
        field: 'fraudulent_status',
        headerName: 'Fraudulent Status',
        flex: 1,
      },
      {
        field: 'current_station',
        headerName: 'Current Station',
        flex: 1,
        renderCell: (params) => <Typography>{capitalizeWord(params.value)}</Typography>,
      },
      {
        field: 'stop_check_status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <Typography>{params.row.batch_id ? 'Batched' : 'Pending'}</Typography>,
      },
      {
        field: 'note',
        headerName: 'Notes',
        flex: 1,
        sortable: false,
      },
      {
        field: 'stop_check_remarks',
        headerName: 'Remarks',
        flex: 2,
        sortable: false,
        renderCell: (params) => (
          <Tooltip
            arrow
            placement="left"
            title={<Typography sx={{ whiteSpace: 'normal', maxWidth: 200 }}>{params.value}</Typography>}
          >
            <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'default' }}>
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'dear_ship_failed_details',
        headerName: 'Shipment Error',
        flex: 2,
        renderCell: ({ value }) => {
          const error = Array.isArray(value) ? value.map((error) => error.Exception).join('\n') : '';
          return (
            <Tooltip
              arrow
              placement="left"
              title={<Typography sx={{ whiteSpace: 'normal', maxWidth: 200 }}>{error}</Typography>}
            >
              <Typography
                color="error"
                sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'default' }}
              >
                {error}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <RowAction
            disabled={status === ORDERS_TAB.BATCHED && params.row.stop_check_flag}
            actions={[
              {
                label: 'Resolve', //Resolve Stop Check
                startIcon: <CheckCircleOutlinedIcon color="success" />,
                color: '#2f7d31',
                hidden: status !== ORDERS_TAB.STOP_CHECK,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenConfirmResolve(true);
                },
              },
              {
                label: 'Stop Check',
                startIcon: <PanToolOutlinedIcon color="info" />,
                color: '#0088d1',
                hidden: ![ORDERS_TAB.PENDING, ORDERS_TAB.RESOLVED, ORDERS_TAB.BATCHED].includes(status),
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenStopCheck(true);
                },
              },
              {
                label: 'Edit Remarks',
                startIcon: <LocalShippingOutlinedIcon color="info" />,
                color: '#0088d1',
                hidden: status !== ORDERS_TAB.STOP_CHECK,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenEditRemarks(true);
                },
              },
              {
                label: 'Edit Shipping Address',
                startIcon: <LocalShippingOutlinedIcon color="warning" />,
                color: '#ed6d03',
                hidden: status !== ORDERS_TAB.STOP_CHECK,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenEditShippingAddress(true);
                },
              },
              {
                label: 'Remove',
                startIcon: <DeleteOutlinedIcon color="error" />,
                color: 'error',
                hidden: status !== ORDERS_TAB.PENDING,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenConfirmDelete(true);
                },
              },
              {
                label: 'Restore',
                startIcon: <RestoreOutlinedIcon color="success" />,
                color: '#2f7d31',
                hidden: status !== ORDERS_TAB.DELETED,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenConfirmRestore(true);
                },
              },
              {
                label: 'Resolve', // Resolve Shipping Issue
                startIcon: <CheckCircleOutlinedIcon color="success" />,
                color: '#2f7d31',
                hidden: status !== ORDERS_TAB.FAILED_SHIPPING,
                action: () => {
                  setSelectedOrderForAction(params.row);
                  setOpenConfirmResolveShipping(true);
                },
              },
            ]}
          />
        ),
      },
    ],
    [status]
  );

  const updateFilter = (filter: any) => {
    if (activeFilter)
      updateOrderFilter(activeFilter.id!, {
        filter,
        filter_name: activeFilter.filter_name,
      }).then(() => {
        enqueueSnackbar(`Filter successfully saved!`, { variant: 'success' });
        filterRef.current.getFilters();
      });
  };

  const handleUpdateShippingAddress = async (data: any) => {
    if (selectedOrderForAction) {
      try {
        setErrorEditShippingAddress(undefined);
        setLoadingEditShippingAddress(true);
        await updateShippingAddress(selectedOrderForAction.id!, data);
        setOpenEditShippingAddress(false);
        setSelectedOrderForAction(undefined);
        refreshTable();
      } catch (error: any) {
        setErrorEditShippingAddress(error.response.data?.error?.replaceAll('.', '. '));
      } finally {
        setLoadingEditShippingAddress(false);
      }
    }
  };

  const handleUpdateStopCheck = async (data: any) => {
    if (selectedOrderForAction) {
      try {
        setLoadingEditRemarks(true);
        await stopCheck(selectedOrderForAction.id!, data);
        setOpenEditRemarks(false);
        enqueueSnackbar(`Remarks successfully updated!`, { variant: 'success' });
        setSelectedOrderForAction(undefined);
        refreshTable();
      } catch (error) {
        enqueueSnackbar(`Please try again!`, { variant: 'error' });
      } finally {
        setLoadingEditRemarks(false);
      }
    }
  };

  useEffect(() => {
    const validate = async () => {
      setValidatingOrders(true);
      setInvalidOrders([]);
      setValidOrders([]);
      setErrors([]);
      setErrorMessage({});
      validateOrders(selectedOrders as string[])
        .then(async (res) => {
          if (res.data.invalid_orders.length) {
            setInvalidOrders((prev) => [...prev, ...res.data.invalid_orders]);
          }
          if (res.data.valid_orders.length) {
            setValidOrders((prev) => [...prev, ...res.data.valid_orders]);
          }

          const error_message = res.data.message;
          const message = Object.keys(error_message).map((order_name: any) => ({
            order_name,
            errors: error_message[order_name].map((error: any) => error.Exception),
          }));
          if (message.length) {
            setErrors((prev) => [...prev, ...message]);
            setErrorMessage((prev: any) => ({ ...prev, ...error_message }));
          }
        })
        .finally(() => {
          setValidatingOrders(false);
        });
    };

    if (openCreateBatch) {
      validate();
    }
  }, [openCreateBatch, selectedOrders]);

  //Filter
  useEffect(() => {
    setOtherFilter((prev: any) => ({
      ...prev,
      start_date: orderDateRangeFilter?.startDate,
      end_date: orderDateRangeFilter?.endDate,
      ship_by_start_date: shipByDateRangeFilter?.startDate,
      ship_by_end_date: shipByDateRangeFilter?.endDate,
      shipping_country: shippingCountryFilter,
      fraudulent_status: fraudulentStatusFilter,
      tags: tagSearch,
      sku: skuSearch,
      station: stationFilter,
    }));
  }, [
    orderDateRangeFilter,
    shipByDateRangeFilter,
    shippingCountryFilter,
    fraudulentStatusFilter,
    stationFilter,
    tagSearch,
    skuSearch,
  ]);

  useEffect(() => {
    setSelectedOrdersDetails((prev: any[]) => {
      return selectedOrders.map((selected) => {
        const existing = prev.find((order) => order.order_name === selected);
        return existing ? existing : rows.find((order) => order.order_name === selected);
      });
    });
  }, [rows, selectedOrders]);

  useEffect(() => {
    setShippingCountryFilter(activeFilter?.filter.shipping_country ?? []);
    setFraudulentStatusFilter(activeFilter?.filter.fraudulent_status ?? []);
    setTagSearch(activeFilter?.filter.tags ?? '');
    setSkuSearch(activeFilter?.filter.sku ?? '');
    if (activeFilter) {
      setOrderDateRangeFilter(getDateRange(activeFilter.filter.order_date));
      setShipByDateRangeFilter(getDateRange(activeFilter.filter.ship_by_date));
    } else {
      setOrderDateRangeFilter({ value: '' });
      setShipByDateRangeFilter({ value: '' });
    }
  }, [activeFilter]);

  const visibleColumns = useMemo(() => {
    switch (status) {
      case ORDERS_TAB.PENDING:
      case ORDERS_TAB.DELETED:
        return columns.filter(
          (col) =>
            ![
              'current_station',
              'stop_check_remarks',
              'batch_id',
              'stop_check_status',
              'tracking_number',
              'dear_ship_failed_details',
            ].includes(col.field)
        );

      case ORDERS_TAB.PARTIAL_FULFILLMENT:
        return columns.filter(
          (col) =>
            ![
              'current_station',
              'stop_check_remarks',
              'batch_id',
              'stop_check_status',
              'tracking_number',
              'dear_ship_failed_details',
              'action',
            ].includes(col.field)
        );

      case ORDERS_TAB.RESOLVED:
        return columns.filter(
          (col) => !['current_station', 'batch_id', 'stop_check_status', 'dear_ship_failed_details'].includes(col.field)
        );

      case ORDERS_TAB.BATCHED:
        return columns.filter(
          (col) => !['stop_check_remarks', 'stop_check_status', 'dear_ship_failed_details'].includes(col.field)
        );

      case ORDERS_TAB.STOP_CHECK:
        return columns.filter((col) => !['current_station', 'dear_ship_failed_details'].includes(col.field));

      case ORDERS_TAB.FAILED_SHIPPING:
        return columns.filter(
          (col) =>
            ![
              'current_station',
              'stop_check_remarks',
              'stop_check_status',
              'tags',
              'fraudulent_status',
              'note',
            ].includes(col.field)
        );

      default:
        return [];
    }
  }, [columns, status]);

  return (
    <>
      <CustomTable
        ref={tableRef}
        rowId="order_name"
        columns={visibleColumns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        getData={getAllOrders}
        customSelectableRow={({ row }) => !row.batch_id && row.dear_order_number}
        searchKeys="Order No., or Notes"
        otherFilters={otherFilter}
        keepNonExistentRowsSelected
        autoSelectedRows={selectedOrders}
        headerComponent={
          ![ORDERS_TAB.PENDING, ORDERS_TAB.RESOLVED].includes(status) ? undefined : (
            <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between" width="100%">
              {/* <Box display="flex" gap="10px" alignItems="center" justifyContent="space-between" width="100%"> */}
              <Box>
                <Box display="flex" gap="10px" alignItems="center">
                  <PrimaryButton
                    disabled={!selectedOrders.length}
                    label="Create Batch"
                    onClick={() => setOpenCreateBatch(true)}
                  />

                  <RegularButton
                    startIcon={<VisibilityOutlinedIcon />}
                    disabled={!selectedOrders.length}
                    label="View Selected Orders"
                    onClick={() => setOpenViewOrders(true)}
                  />
                  <RegularButton
                    color="error"
                    startIcon={<DeleteOutlinedIcon />}
                    disabled={!selectedOrders.length}
                    label="Delete Selected Orders"
                    onClick={() => {
                      setSelectedOrderForAction(undefined);
                      setOpenConfirmDelete(true);
                    }}
                    styles={{ marginLeft: '20px' }}
                  />
                </Box>
                {/* {status === ORDERS_TAB.PENDING && (
                  <Box display="flex" alignItems="center" gap="10px" mt="10px">
                    <Typography>Orders per batch:</Typography>
                    <CustomTextField
                      label="Order Count"
                      fieldName="count"
                      sx={{ width: '120px' }}
                      value={autoBatchOrderCount}
                      type="number"
                      handleChange={(event) => setAutoBatchOrderCount(parseInt(event.target.value))}
                    />
                    <RegularButton
                      startIcon={<SmartToyOutlinedIcon />}
                      color="info"
                      label="Auto Batch"
                      loading={autoBatchLoading}
                      onClick={() => {
                        setAutoBatchLoading(true);
                        autoBatchOrders(autoBatchOrderCount)
                          .then((res) => {
                            setBatches(res.data);
                            console.log(res.data);
                            setOpenViewAutoBatchOrders(true);
                          })
                          .finally(() => setAutoBatchLoading(false));
                      }}
                    />
                  </Box>
                )} */}
              </Box>

              {/* </Box> */}
              <Box display="flex" flexDirection="column" gap="10px">
                <Box display="flex" alignItems="center" gap="10px" mt="10px">
                  <Typography>Select the first:</Typography>
                  <CustomTextField
                    label="Order Count"
                    fieldName="count"
                    sx={{ width: '120px' }}
                    value={autoSelect}
                    type="number"
                    handleChange={(event) => setAutoSelect(event.target.value)}
                  />
                  <RegularButton label="Auto Select" onClick={autoSelectOrders} disabled={!autoSelect} />
                  <RegularButton
                    label="Clear Selected"
                    disabled={!selectedOrders.length}
                    onClick={() => {
                      setSelectedOrders([]);
                      tableRef.current.clearSelected();
                      setAutoSelect('');
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )
        }
        filterComponent={
          ORDERS_TAB.FAILED_SHIPPING === status ? (
            <Typography variant="h3" fontWeight="bold" color="error">
              Shipment with error
            </Typography>
          ) : (
            <Box>
              {status === ORDERS_TAB.PENDING && (
                <OrderPresetFilter ref={filterRef} handleUpdateFilter={(value) => setActiveFilter(value)} />
              )}

              <Box display="flex" gap="10px" alignItems="flex-end">
                <Box display="flex" gap="10px" alignItems="flex-start" flexDirection="column">
                  <Box display="flex" gap="10px" alignItems="flex-end">
                    <CustomDateQuickFilter
                      label="Order Date"
                      pastOnly
                      handleChangeCallback={(value) => value && setOrderDateRangeFilter(value)}
                      value={orderDateRangeFilter?.value}
                    />
                    <Box>
                      <CustomDateQuickFilter
                        label="Ship By Date"
                        futureOnly
                        handleChangeCallback={(value) => value && setShipByDateRangeFilter(value)}
                        value={shipByDateRangeFilter?.value}
                      />
                    </Box>

                    <SearchBar
                      label="Search Tag"
                      handleSearch={(value) => setTagSearch(value)}
                      value={tagSearch}
                      hideSearchIcon
                    />
                    <SearchBar
                      label="Search SKU"
                      handleSearch={(value) => setSkuSearch(value)}
                      value={skuSearch}
                      hideSearchIcon
                    />
                  </Box>

                  <Box display="flex" gap="10px">
                    <CustomFilter
                      buttonLabel="Fraudulent Status"
                      optionList={[
                        { label: 'Low', id: 'Low' },
                        { label: 'High', id: 'High' },
                      ]}
                      selected={fraudulentStatusFilter}
                      setSelected={setFraudulentStatusFilter}
                      buttonStyle={{ height: '48px' }}
                    />

                    <CustomFilter
                      buttonLabel="Shipping Country"
                      optionList={[
                        { label: 'Singapore', id: 'sg' },
                        { label: 'Outside Singapore', id: 'out' },
                        { label: 'Empty', id: 'blank' },
                      ]}
                      selected={shippingCountryFilter}
                      setSelected={setShippingCountryFilter}
                      buttonStyle={{ height: '48px' }}
                    />

                    {status === ORDERS_TAB.BATCHED && (
                      <CustomFilter
                        buttonLabel="Current Station"
                        optionList={[
                          { label: 'Sorting Station', id: 'sorting' },
                          { label: 'Scanning Station', id: 'scanning' },
                          { label: 'Shipping Station', id: 'shipping' },
                          { label: 'Completed', id: 'completed' },
                        ]}
                        selected={stationFilter}
                        setSelected={setStationFilter}
                        buttonStyle={{ height: '48px' }}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" gap="10px" alignItems="center">
                  <CustomIconButton
                    tooltip="Clear Filter"
                    icon={<ClearIcon />}
                    onClick={() => {
                      setOrderDateRangeFilter({ value: '' });
                      setShipByDateRangeFilter({ value: '' });
                      setSkuSearch('');
                      setShippingCountryFilter([]);
                      setTagSearch('');
                    }}
                  />
                  {status === ORDERS_TAB.STOP_CHECK && (
                    <RegularButton
                      color="info"
                      startIcon={<AddIcon />}
                      label="Add New"
                      onClick={() => {
                        setSelectedOrderForAction(undefined);
                        setOpenStopCheck(true);
                      }}
                    />
                  )}
                  <RegularButton
                    variant={'outlined'}
                    color="secondary"
                    size="small"
                    label="Save Filter"
                    onClick={() =>
                      updateFilter({
                        order_date: orderDateRangeFilter?.value,
                        ship_by_date: shipByDateRangeFilter?.value,
                        shipping_country: shippingCountryFilter,
                        fraudulent_status: fraudulentStatusFilter,
                        tags: tagSearch,
                        sku: skuSearch,
                      })
                    }
                    styles={{
                      borderRadius: '20px',
                      padding: '10px 20px',
                      marginInline: '15px',
                      display: activeFilter ? 'block' : 'none',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )
        }
        checkboxSelection={[ORDERS_TAB.PENDING, ORDERS_TAB.RESOLVED].includes(status)}
        handleSelectRow={(selected) => setSelectedOrders(selected)}
      />

      <CustomModal header="Create Batch" open={openCreateBatch} setOpen={setOpenCreateBatch} width={700}>
        <Box display="flex" flexDirection="column" gap="20px">
          {validatingOrders ? (
            <Box display="flex" gap="10px">
              <CircularProgress color="inherit" size="1.3rem" />
              <Typography mb="10px">Please wait, validating orders</Typography>
            </Box>
          ) : (
            <>
              <Box display="flex" gap="20px" width="100%">
                <Box flex="1">
                  <Typography variant="h4" fontWeight="bold" color={colors.greenAccent}>
                    Total Valid Orders:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="30px">
                    {selectedOrdersDetails.length - invalidOrders.length}
                  </Typography>
                </Box>
                <Box flex="1">
                  <Typography variant="h4" fontWeight="bold" color={colors.redAccent}>
                    Total Invalid Orders:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="30px">
                    {invalidOrders.length}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap="10px">
                <CustomForm
                  onSubmit={(data) => createOrderBatch(data)}
                  fields={[{ field_name: 'batch_name', display_name: 'Batch Name', span: 4 }]}
                  initialValues={{ batch_name: '' }}
                  schema={batch_schema}
                  loading={loadingCreateBatch}
                  disabled={validatingOrders}
                />
              </Box>

              {!!invalidOrders.length && (
                <>
                  <Box>
                    <Typography mb="10px">
                      Submitting the form will create separate batch for the invalid orders.
                    </Typography>
                    <Typography>The following orders contain errors: {invalidOrders.join(', ')}.</Typography>

                    <Box mt="20px">
                      {errors.map((order, index) => (
                        <Box key={index}>
                          <Typography fontWeight="bold">Order {order.order_name}</Typography>
                          <List sx={{ paddingLeft: '10px' }}>
                            {order.errors.map((error: any, index2: number) => (
                              <ListItem key={index + '_' + index2} disablePadding>
                                <Typography key={index}>{error}</Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </CustomModal>

      <FlagOrderModal
        open={openStopCheck}
        setOpen={setOpenStopCheck}
        preSelectedOrder={selectedOrderForAction}
        onFlag={(order) => {
          refreshTable();
          if (stopCheckTableRef) stopCheckTableRef.current.refreshTable();
          if (batchedTableRef && order.batch_id) batchedTableRef.current.refreshTable();
          if (pendingTableRef && !order.batch_id) pendingTableRef.current.refreshTable();
          setSelectedOrderForAction(undefined);
        }}
      />

      <CustomModal
        header={`Selected Orders ( ${selectedOrdersDetails.length} )`}
        open={openViewOrders}
        setOpen={setOpenViewOrders}
        width={1200}
        headerComponent={
          <PrimaryButton
            disabled={!selectedOrders.length}
            label="Create Batch"
            onClick={() => setOpenCreateBatch(true)}
            loading={loadingCreateBatch}
          />
        }
      >
        <Box display="flex" flexDirection="column" width="100%" gap="10px">
          {selectedOrdersDetails.map((order, index) => (
            <OrderRow order={order} index={index} key={order.id} />
          ))}
        </Box>
      </CustomModal>

      <CustomModal
        header={`Edit Shipping Address - ${selectedOrderForAction?.order_name}`}
        open={openEditShippingAddress}
        setOpen={setOpenEditShippingAddress}
      >
        {errorEditShippingAddress && (
          <Typography color="error" sx={{ fontSize: '16px', fontWeight: 'bold', mb: '10px' }}>
            Error{errorEditShippingAddress}
          </Typography>
        )}
        <CustomForm
          onSubmit={handleUpdateShippingAddress}
          fields={[
            {
              field_name: 'country',
              display_name: 'Country',
              type: 'select',
              span: 4,
              options: [
                { key: 'Australia', value: 'AU' },
                { key: 'Brunei', value: 'BN' },
                { key: 'Canada', value: 'CA' },
                { key: 'Germany', value: 'DE' },
                { key: 'United Kingdom', value: 'GB' },
                { key: 'Hong Kong', value: 'HK' },
                { key: 'Indonesia', value: 'ID' },
                { key: 'Japan', value: 'JP' },
                { key: 'Malaysia', value: 'MY' },
                { key: 'New Zealand', value: 'NZ' },
                { key: 'Philippines', value: 'PH' },
                { key: 'Singapore', value: 'SG' },
                { key: 'Taiwan', value: 'TW' },
                { key: 'United States', value: 'US' },
                { key: 'Vietnam', value: 'VN' },
              ],
            },
            { field_name: 'first_name', display_name: 'First Name' },
            { field_name: 'last_name', display_name: 'Last Name' },
            { field_name: 'company', display_name: 'Company', span: 4 },
            { field_name: 'address1', display_name: 'Address', span: 4 },
            { field_name: 'address2', display_name: 'Apartment, suite, etc', span: 4 },
            { field_name: 'zip', display_name: 'Postal Code' },
            { field_name: 'city', display_name: 'City' },
            { field_name: 'province', display_name: 'Region' },
            { field_name: 'phone', display_name: 'Phone' },
          ]}
          initialValues={{
            country: selectedOrderForAction?.shopify_data?.shipping_address?.country_code + '',
            first_name: selectedOrderForAction?.shopify_data?.shipping_address?.first_name + '',
            last_name: selectedOrderForAction?.shopify_data?.shipping_address?.last_name + '',
            company: selectedOrderForAction?.shopify_data?.shipping_address?.company + '',
            address1: selectedOrderForAction?.shopify_data?.shipping_address?.address1 + '',
            address2: selectedOrderForAction?.shopify_data?.shipping_address?.address2 + '',
            zip: selectedOrderForAction?.shopify_data?.shipping_address?.zip + '',
            city: selectedOrderForAction?.shopify_data?.shipping_address?.city + '',
            province: selectedOrderForAction?.shopify_data?.shipping_address?.province + '',
            phone: selectedOrderForAction?.shopify_data?.shipping_address?.phone + '',
          }}
          schema={shipping_address_schema}
          loading={loadingEditShippingAddress}
        />
      </CustomModal>

      <CustomModal
        header={`Edit Remarks - ${selectedOrderForAction?.order_name}`}
        open={openEditRemarks}
        setOpen={setOpenEditRemarks}
      >
        <CustomForm
          onSubmit={handleUpdateStopCheck}
          fields={[
            {
              field_name: 'stop_check_remarks',
              display_name: 'Remarks',
              multiline: true,
              rows: 5,
              span: 4,
            },
          ]}
          schema={stop_check_schema}
          loading={loadingEditRemarks}
          initialValues={{
            stop_check_remarks: selectedOrderForAction?.stop_check_remarks ?? '',
          }}
        />
      </CustomModal>

      <CustomModal
        header={`Auto Batching Confirmation`}
        open={openViewAutoBatchOrders}
        setOpen={setOpenViewAutoBatchOrders}
        width={1200}
        headerComponent={
          <PrimaryButton
            styles={{ mb: '10px', ml: '10px' }}
            label={'Create Batch' + (batches.length ? 'es' : '')}
            onClick={handleAutoCreateBatches}
            loading={autoBatchConfirmLoading}
          />
        }
      >
        <Box>
          {batches.map((batch, batchIndex) => (
            <Accordion key={batch.batch_name}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" gap="20px" alignItems="center">
                  <Box
                    sx={{
                      backgroundColor: colors.secondary,
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {batchIndex + 1}
                  </Box>
                  <Box display="flex" gap="10px" alignItems="center">
                    <Typography fontSize="18px" fontWeight="bold">
                      {batch.batch_name}
                    </Typography>
                    <Chip label={<Typography>{batch.orders.length} Orders</Typography>} color="primary" size="small" />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ width: '100%' }}>
                <Box display="flex" flexDirection="column" width="100%" gap="10px">
                  {batch.orders.map((order, index) => (
                    <OrderRow order={order} index={index} key={order.id} />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </CustomModal>

      <ConfirmationDialog
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        message={`Are you sure you want to remove the following orders: ${
          selectedOrderForAction ? selectedOrderForAction.order_name : selectedOrders.join(', ')
        }?`}
        onConfirm={() =>
          deleteOrder(
            selectedOrderForAction ? [selectedOrderForAction?.order_name!] : (selectedOrders as string[])
          ).then(() => {
            refreshTable();
            if (deletedTableRef) deletedTableRef.current?.refreshTable();
          })
        }
        setLoading={setLoading}
        loading={loading}
      />

      <ConfirmationDialog
        open={openConfirmResolve}
        setOpen={setOpenConfirmResolve}
        message={`Are you sure you want to resolve Order ${selectedOrderForAction?.order_name}?`}
        onConfirm={() =>
          resolveStopCheck(selectedOrderForAction?.id!).then(() => {
            refreshTable();
            if (resolvedTableRef) resolvedTableRef.current.refreshTable();
            if (batchedTableRef) batchedTableRef.current?.refreshTable();
            setOpenSuccessResolve(true);
          })
        }
        loading={loading}
        setLoading={setLoading}
      />

      <ConfirmationDialog
        open={openSuccessResolve}
        setOpen={setOpenSuccessResolve}
        title="Success"
        titleColor={colors.greenAccent}
        message={
          selectedOrderForAction?.batch_id
            ? `Order ${selectedOrderForAction?.order_name} has been unflagged in Batch SN-${selectedOrderForAction.batch_id} for further order fulfilment.`
            : `Order ${selectedOrderForAction?.order_name} has been moved to the "Resolved" tab for further order fulfillment.`
        }
        cancelButtonLabel="Okay"
      />

      <ConfirmationDialog
        open={openConfirmRestore}
        setOpen={setOpenConfirmRestore}
        message={`Are you sure you want to restore Order ${selectedOrderForAction?.order_name}?`}
        onConfirm={() =>
          restoreOrder(selectedOrderForAction?.id!).then(() => {
            refreshTable();
            if (pendingTableRef) pendingTableRef.current?.refreshTable();
          })
        }
        setLoading={setLoading}
        loading={loading}
      />

      <ConfirmationDialog
        title="Confirmation"
        open={openConfirmResolveShipping}
        setOpen={setOpenConfirmResolveShipping}
        message={`Are you sure you want to proceed with the shipment for ${selectedOrderForAction?.order_name}?`}
        onConfirm={() =>
          markAsShippedByOrderId(selectedOrderForAction?.id!)
            .then(() => {
              enqueueSnackbar(`Order ${selectedOrderForAction?.order_name} successfully marked as shipped.`, {
                variant: 'success',
              });
              refreshTable();
            })
            .catch((error) => {
              enqueueSnackbar(`Shipment for Order ${selectedOrderForAction?.order_name} failed.`, {
                variant: 'error',
              });
              refreshTable();
            })
        }
        loading={loadingShipConfirm}
        setLoading={setLoadingShipConfirm}
      />
    </>
  );
});

export default OrdersTable;

import * as React from 'react';

import { Box, Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { RegularButton } from 'components';
import { tokens } from 'context/theme.context';

type ConfirmationDialogProps = {
  title?: string;
  message: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  open: boolean;
  setOpen: (openDialog: boolean) => void;
  onConfirm?: () => Promise<any>;
  onClose?: () => void;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  titleColor?: string;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  message,
  setOpen,
  open,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  loading,
  setLoading,
  onClose,
  titleColor,
}) => {
  const colors = tokens();

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <div>
      <Dialog open={open} maxWidth="md" PaperProps={{ sx: { borderRadius: '20px' } }}>
        <Box p="25px" width="500px">
          <DialogContent>
            {title && (
              <Typography fontWeight="bold" variant="h3" mb="10px" color={titleColor}>
                {title}
              </Typography>
            )}
            <DialogContentText sx={{ fontSize: '17px', color: colors.text }}>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <RegularButton
              onClick={handleClose}
              label={cancelButtonLabel ?? 'Cancel'}
              variant="outlined"
              styles={{
                backgroundColor: 'none',
                borderColor: colors.text,
                color: colors.text,
              }}
              disabled={loading}
            />
            {onConfirm && (
              <RegularButton
                label={confirmButtonLabel ?? 'Yes'}
                loading={loading}
                onClick={() => {
                  setLoading && setLoading(true);
                  onConfirm()
                    .then(() => setOpen(false))
                    .finally(() => setLoading && setLoading(false));
                }}
                styles={{ backgroundColor: colors.redAccent }}
                color="error"
              />
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;

import {
  ActivityLogs,
  Batches,
  ForbiddenScreen,
  LoginForm,
  NotFoundScreen,
  Orders,
  ScanningStation,
  ShippingStation,
  SortingStation,
  StopCheck,
  UserManagement,
} from 'screens';
import { BreadcrumbContext, useBreadcrumb } from 'context/breadcrumb.context';
import { ColorModeContext, useMode } from './context/theme.context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CustomScnackBarWrapper, ProtectedRoute } from 'components';
import { Route, Routes } from 'react-router-dom';
import { UserContext, useUser } from './context/user.context';

function App() {
  const [theme, colorMode] = useMode();
  const [user] = useUser();
  const [breadcrumb] = useBreadcrumb();

  return (
    <>
      <ColorModeContext.Provider value={colorMode as any}>
        <ThemeProvider theme={theme as any}>
          <UserContext.Provider value={user as any}>
            <BreadcrumbContext.Provider value={breadcrumb as any}>
              <CssBaseline />
              <div className="app">
                <CustomScnackBarWrapper>
                  <Routes>
                    <Route path="/login" element={<LoginForm />} />

                    <Route path="/" element={<ProtectedRoute />}>
                      <Route index element={<Orders />} />
                      <Route
                        path="batches"
                        element={<ProtectedRoute requiredAuth={['MANAGE_BATCHES']} element={<Batches />} />}
                      />
                      <Route
                        path="sorting"
                        element={<ProtectedRoute requiredAuth={['MANAGE_BATCHES']} element={<SortingStation />} />}
                      />
                      <Route
                        path="scanning"
                        element={<ProtectedRoute requiredAuth={['MANAGE_BATCHES']} element={<ScanningStation />} />}
                      />
                      <Route
                        path="shipping"
                        element={<ProtectedRoute requiredAuth={['MANAGE_BATCHES']} element={<ShippingStation />} />}
                      />
                      <Route
                        path="stop-check"
                        element={<ProtectedRoute requiredAuth={['MANAGE_BATCHES']} element={<StopCheck />} />}
                      />
                      <Route
                        path="user-management"
                        element={<ProtectedRoute requiredAuth={['MANAGE_USERS']} element={<UserManagement />} />}
                      />
                      <Route
                        path="activity-logs"
                        element={<ProtectedRoute requiredAuth={['VIEW_ACTIVITY_LOGS']} element={<ActivityLogs />} />}
                      />

                      <Route path="not-found" element={<NotFoundScreen />} />
                      <Route path="forbidden" element={<ForbiddenScreen />} />
                      <Route path="*" element={<NotFoundScreen />} />
                    </Route>
                  </Routes>
                </CustomScnackBarWrapper>
              </div>
            </BreadcrumbContext.Provider>
          </UserContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;

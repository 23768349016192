import { Box, Typography } from '@mui/material';

import { DisplayField } from 'components';
import { OrderModel } from 'model/Entities';
import { formatDate } from 'utils';
import { tokens } from 'context/theme.context';

type Props = {
  order: OrderModel;
  index: number;
};

const OrderRow: React.FC<Props> = ({ order, index }) => {
  const colors = tokens();
  return (
    <Box
      display="flex"
      gap="30px"
      key={order.order_name}
      width="100%"
      alignItems="center"
      sx={{ backgroundColor: colors.secondary_background, padding: '10px 20px', borderRadius: '10px' }}
    >
      <Box
        sx={{
          backgroundColor: colors.primary,
          fontWeight: 'bold',
          fontSize: '16px',
          width: '35px',
          height: '35px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {index + 1}
      </Box>
      <DisplayField field_name="Order No." field_value={order.order_name} />
      <DisplayField field_name="Order Date" field_value={formatDate(order.order_date)} />
      <DisplayField field_name="Ship By" field_value={formatDate(order.ship_by)} />
      <DisplayField field_name="Shipping Country" field_value={order.shipping_country ? order.shipping_country : '-'} />
      <DisplayField field_name="Tags" field_value={order.tags ? order.tags : '-'} />
      <DisplayField
        field_name="SKU"
        flex={1.5}
        field_value={
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
            {order.line_items?.map((item: any, index: number) => (
              <Typography key={index}>{item.sku}</Typography>
            ))}
          </Box>
        }
        isComponent
      />
    </Box>
  );
};

export default OrderRow;

import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'order';

export const getOrderByOrderName = (order_name: string) => {
  return axios.get(`${baseURL}/${path}/order/name`, { params: { order_name } });
};

export const getOrder = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getOrders = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getSkuLocations = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/sku-locations/${batch_id}`);
};

export const getOrdersByBatch = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/batch/${batch_id}`);
};

export const getShippingSummary = () => {
  return axios.get(`${baseURL}/${path}/shipping/summary`);
};

export const getReadyToShipSummary = () => {
  return axios.get(`${baseURL}/${path}/ready-to-ship/summary`);
};

export const getUnfulfilledInShopify = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/unfulfilled/shopify`, { params: query });
};

export const autoBatchOrders = (order_count: number) => {
  return axios.get(`${baseURL}/${path}/auto-batch/orders/${order_count}`);
};

export const removeFromBatch = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/remove-from-batch/${order_id}`);
};

export const syncDear = () => {
  return axios.post(`${baseURL}/${path}/sync-dear`);
};

export const syncDearReverese = (start: string) => {
  return axios.post(`${baseURL}/${path}/sync-dear-reverse/${start}`);
};

export const processShipment = (company: string, orders: string[], pickup_date: string) => {
  return axios.post(
    `${baseURL}/${path}/process-shipment`,
    { orders, company, pickup_date },
    { responseType: 'arraybuffer' }
  );
};

export const addPickedCount = (line_item_id: number) => {
  return axios.patch(`${baseURL}/${path}/line-item/pick/${line_item_id}`);
};

export const stopCheck = (order_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/stop-check/${order_id}`, data);
};

export const resolveStopCheck = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/stop-check/resolve/${order_id}`);
};

export const restoreOrder = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/restore/${order_id}`);
};

export const markAsPacked = (order_id: number) => {
  return axios.post(`${baseURL}/${path}/mark-as-packed/${order_id}`);
};

export const markAsReadyToShip = (orders: any[], confirmed?: boolean) => {
  return axios.post(`${baseURL}/${path}/mark-as-ready-to-ship`, { orders, confirmed });
};

export const markAsShipped = (carrier_id: number) => {
  return axios.post(`${baseURL}/${path}/mark-as-shipped/${carrier_id}`);
};

export const markAsShippedByOrderId = (order_id: number) => {
  return axios.post(`${baseURL}/${path}/mark-as-shipped-by-order-id/${order_id}`);
};

export const printLabel = (orders: string[]) => {
  return axios.post(`${baseURL}/${path}/print-label`, { orders }, { responseType: 'arraybuffer' });
};

export const deleteOrder = (orders: string[]) => {
  return axios.delete(`${baseURL}/${path}`, { params: { orders } });
};

export const updateShippingAddress = (order_id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/shipping-address/${order_id}`, data);
};

export const partialFulfillment = (order_id: number) => {
  return axios.patch(`${baseURL}/${path}/partial-fulfillment/${order_id}`);
};

export const partialFulfillmentMultiple = (orders: string[]) => {
  return axios.post(`${baseURL}/${path}/partial-fulfillment/multiple`, { orders });
};

import { Button, CircularProgress, SxProps, Theme } from '@mui/material';

type RegularButtonProps = {
  label?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  styles?: SxProps<Theme>;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  id?: string;
  fullWidth?: boolean;
};

const RegularButton: React.FC<RegularButtonProps> = ({
  label = 'Submit',
  onClick,
  disabled,
  loading,
  loadingText,
  styles,
  color,
  variant = 'contained',
  size,
  startIcon,
  endIcon,
  id,
  fullWidth,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      id={id}
      sx={{
        textTransform: 'none',
        borderRadius: 2,
        padding: size === 'small' ? '4px 12px' : '7px 25px',
        fontWeight: 'bold',
        '@media screen and (max-width: 768px)': { width: '140px', height: '40px', padding: '0px' },
        ...styles,
      }}
      startIcon={loading ? undefined : startIcon}
      endIcon={loading ? undefined : endIcon}
      disabled={disabled || loading}
      color={color}
    >
      {!loading ? (
        label
      ) : (
        <>
          <CircularProgress color="inherit" size="1rem" sx={{ marginRight: '10px' }} />
          {loadingText ?? label}
        </>
      )}
    </Button>
  );
};

export default RegularButton;

import { Box, Modal, Paper, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CustomIconButton from './buttons/IconButton';
import React from 'react';

type CustomModalProps = {
  header: string;
  headerComponent?: any;
  children: React.ReactNode;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  width?: number | string;
  onClose?: () => void;
  pt?: number | string;
  placeAtTop?: boolean;
};

const CustomModal: React.FC<CustomModalProps> = ({
  header,
  headerComponent,
  children,
  open,
  setOpen,
  width,
  onClose,
  pt,
  placeAtTop,
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: placeAtTop ? '10%' : '50%',
    left: '50%',
    transform: placeAtTop ? 'translate(-50%, -10%)' : 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: width ? width : 700,
    bgColor: 'background.paper',
    p: 4,
    boxShadow: 24,
    borderRadius: 2,
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Modal open={open}>
      <Paper sx={style} elevation={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              // alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography variant="h3" component="h2" fontWeight="bold">
              {header}
            </Typography>
            {headerComponent}
          </Box>
          <CustomIconButton icon={<CloseIcon />} color={null} onClick={handleClose} />
        </Box>
        <Box pt={pt ?? 4} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
          {children}
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomModal;

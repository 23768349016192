import { Box, Divider, Typography } from '@mui/material';
import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  CustomSelectField,
  CustomTable,
  CustomTextField,
  Header,
  RegularButton,
} from 'components';
import { capitalizeWord, formatDateWithDay, formatNumber } from 'utils';
import { getReadyToShipSummary, getShippingSummary, markAsReadyToShip, markAsShipped } from 'api/order';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import { ORDERS_TAB } from 'model/Entities';
import OrdersTable from 'screens/Orders/components/OrdersTable';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { UserContext } from 'context/user.context';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

type OrderToShip = {
  carrier_id: string;
  order_name: string;
  tracking_number: string;
};

const ShippingStation = () => {
  const colors = tokens();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState<OrderToShip[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>();
  const [summary, setSummary] = useState({
    shipped: [] as any[],
    not_shipped: [] as any[],
    for_confirmation: [] as any[],
  });
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openShippingSummary, setOpenShippingSummary] = useState(false);
  const [openReadyToShipSummary, setOpenReadyToShipSummary] = useState(false);

  const [shippingSummaryRows, setShippingSummaryRows] = useState<any[]>([]);
  const [readyToShipRows, setReadyToShipRows] = useState<any[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [openShipConfirmation, setOpenShipConfirmation] = useState(false);
  const [loadingShipConfirm, setLoadingShipConfirm] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState({ carrier_id: 0, carrier_name: '', order_count: 0 });

  const rowCountDefault = 10;

  useEffect(() => {
    setBreadcrumb([{ label: 'Shipping Station' }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewRows = () => {
    setOrders((prev) => [
      ...prev,
      ...Array.from(Array(newRowCount).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' })),
    ]);
    setNewRowCount(0);
  };

  const applyCarrierToAll = (carrier_id: string) => {
    if (carrier_id) {
      setOrders((prev) => {
        prev.forEach((order) => (order.carrier_id = carrier_id));
        return [...prev];
      });
      setTimeout(() => updateOrdersInLocalStorage(), 400);
    }
  };

  const clearRows = () => {
    setOrders([]);
    clearOrdersInLocalStorage();
    setTimeout(() => {
      setOrders((prev) =>
        Array.from(Array(rowCountDefault).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' }))
      );
    }, 200);
  };

  const [inputValues, setInputValues] = useState<string[]>(Array(8).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, orders.length * 2);
  }, [orders]);

  useEffect(() => {
    const items = localStorage.getItem('shipping_orders');
    const ordersLocal: OrderToShip[] = JSON.parse(items ?? '[]');
    if (items?.length) {
      if (firstLoad) {
        setOrders(ordersLocal);
        setFirstLoad(false);
      }
    } else {
      setOrders(
        Array.from(Array(rowCountDefault).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('shipping_orders')]);

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    updateOrdersInLocalStorage();

    if (inputRefs.current[index]) {
      clearTimeout((inputRefs.current[index] as any).timer);
    }

    (inputRefs.current[index] as any).timer = setTimeout(() => {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }, 1000);
  };

  const handleFocus = (index: number) => {
    inputRefs.current[index]?.select();
  };

  const updateOrdersInLocalStorage = () => {
    localStorage.setItem('shipping_orders', JSON.stringify(orders));
  };

  const clearOrdersInLocalStorage = () => {
    localStorage.removeItem('shipping_orders');
  };

  const handleMarkAsReadyToShip = async (fromForConfirmation?: boolean) => {
    let with_inputs = orders.filter((order) => order.order_name);
    if (with_inputs.length) {
      if (fromForConfirmation) {
        const forConfirmationList = summary.for_confirmation?.map((order) => order.order_name);
        with_inputs = with_inputs.filter((order) => forConfirmationList.includes(order.order_name));
      }
      setLoading(true);
      markAsReadyToShip(with_inputs, fromForConfirmation)
        .then((res) => {
          setOpenSummaryModal(true);
          setSummary(res.data);
        })
        .finally(() => setLoading(false));
    } else {
      enqueueSnackbar(`There are no orders to process. Please fill in the form.`, { variant: 'error' });
    }
  };

  const setShippingSummary = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoadingTable(true);
      try {
        const res = await getShippingSummary();
        const groupedData: any = res.data.reduce((acc: any, item: any) => {
          const date = formatDateWithDay(item.shipping_date, 'No Date'); // Extract the date part only
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});

        const final = Object.keys(groupedData).map((key) => {
          const carriers: any = groupedData[key];
          let total = 0;

          const carrier_data: any[] = carriers.reduce((result: any, carrier: any) => {
            if (carrier.shipping_carrier) {
              if (result[carrier.shipping_carrier.carrier_name]) {
                result[carrier.shipping_carrier.carrier_name] =
                  result[carrier.shipping_carrier.carrier_name] + parseInt(carrier.total_orders);
              } else {
                result[carrier.shipping_carrier.carrier_name] = parseInt(carrier.total_orders);
              }
              total += parseInt(carrier.total_orders);
            }
            return result;
          }, {});
          return {
            date: key,
            total,
            ...carrier_data,
          };
        });
        setShippingSummaryRows(final);
        // setRowCount(final);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      } finally {
        setLoadingTable(false);
      }
    };

    fetchData();
  };

  const setReadyToShipSummary = async () => {
    const fetchData = async () => {
      setLoadingTable(true);
      try {
        const res = await getReadyToShipSummary();
        const groupedData: any = res.data.reduce((acc: any, item: any) => {
          const status = item.shipping_status;
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(item);
          return acc;
        }, {});

        const final = Object.keys(groupedData).map((key) => {
          const carriers: any = groupedData[key];
          let total = 0;

          const carrier_data: any[] = carriers.reduce((result: any, carrier: any) => {
            if (carrier.shipping_carrier) {
              if (result[carrier.shipping_carrier.carrier_name]) {
                result[carrier.shipping_carrier.carrier_name] =
                  result[carrier.shipping_carrier.carrier_name] + parseInt(carrier.total_orders);
              } else {
                result[carrier.shipping_carrier.carrier_name] = parseInt(carrier.total_orders);
              }
              total += parseInt(carrier.total_orders);
            }
            return result;
          }, {});
          return {
            status: key,
            total,
            ...carrier_data,
          };
        });
        const readyCount = final.find((result) => result.status === 'ready') ?? { status: 'ready', total: 0 };
        const confirmedCount = final.find((result) => result.status === 'confirmed') ?? {
          status: 'confirmed',
          total: 0,
        };
        setReadyToShipRows([readyCount, confirmedCount]);
        // setRowCount(final);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      } finally {
        setLoadingTable(false);
      }
    };

    fetchData();
  };

  const shippingSummaryColumns = useMemo(() => {
    if (user) {
      const carriers = user.shipping_carriers.map((carrier) => ({
        field: carrier.carrier_name,
        headerName: carrier.carrier_name,
        flex: 1,
        renderCell: ({ value }: any) => <CustomGridCell>{value ? formatNumber(value, 0) : '0'}</CustomGridCell>,
      }));
      return [
        {
          field: 'date',
          headerName: 'Date of Shipping',
          flex: 1,
        },
        ...carriers,
        {
          field: 'total',
          headerName: 'Total',
          flex: 1,
        },
      ];
    }
    return [];
  }, [user]);

  const readyToShipColumns: any = useMemo(() => {
    if (user) {
      const carriers = user.shipping_carriers.map((carrier) => ({
        field: carrier.carrier_name,
        headerName: carrier.carrier_name,
        flex: 1,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row, value }: any) => (
          <CustomGridCell
            style={{
              paddingBottom: row.status === 'ready' ? '30px' : undefined,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="50px" fontWeight="bold">
              {value ? formatNumber(value, 0) : '0'}
            </Typography>
            {row.status === 'ready' && (
              <RegularButton
                label="Mark as shipped"
                disabled={!value}
                onClick={() => {
                  setOpenShipConfirmation(true);
                  setSelectedCarrier({
                    carrier_id: carrier.id,
                    carrier_name: carrier.carrier_name,
                    order_count: value ?? 0,
                  });
                }}
              />
            )}
          </CustomGridCell>
        ),
      }));

      return [
        {
          field: 'status',
          headerName: 'Status',
          flex: 1,
          renderCell: ({ row, value }: any) => (
            <Box sx={{ textWrap: 'wrap' }}>
              <Typography fontWeight="bold">{capitalizeWord(value)}</Typography>
              {value === 'confirmed' && (
                <Typography fontSize="12px">
                  Confirmed orders are orders marked as shipped but have not yet had the email confirmation sent to the
                  customer. These will be processed between 12 AM and 5 AM.
                </Typography>
              )}
            </Box>
          ),
        },
        ...carriers,
        {
          field: 'total',
          headerName: 'Total',
          flex: 1,
          sortable: false,
          headerAlign: 'center',
          align: 'center',
          renderCell: ({ row, value }: any) => (
            <CustomGridCell>
              <Typography fontSize={'70px'} fontWeight="bold" color="secondary">
                {value ? formatNumber(value, 0) : '0'}
              </Typography>
            </CustomGridCell>
          ),
        },
      ];
    }
    return [];
  }, [user]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Header title="Shipping Station" />
        <Box display="flex" gap="10px">
          <RegularButton
            label="View Ready To Ship Summary"
            onClick={() => setOpenReadyToShipSummary(true)}
            startIcon={<RemoveRedEyeOutlinedIcon />}
          />
          <RegularButton
            color="secondary"
            label="View Shipping Summary"
            onClick={() => setOpenShippingSummary(true)}
            startIcon={<RemoveRedEyeOutlinedIcon />}
          />
        </Box>
      </Box>

      <Box display="flex" gap="20px" alignItems="flex-start" position="relative">
        <Box display="flex" flexDirection="column" gap="10px" width="75%">
          <Box
            paddingX="20px"
            display="flex"
            gap="20px"
            width="100%"
            alignItems="center"
            sx={{ borderRadius: '10px', '& p': { fontWeight: 'bold' } }}
          >
            <Typography flex={0.5}>#</Typography>
            <Typography flex={2}>Carrier</Typography>
            <Typography flex={0.8}></Typography>
            <Typography flex={2}>Order No.</Typography>
            <Typography flex={2}>Tracking Number</Typography>
          </Box>
          {orders.map((order, index) => (
            <Box
              key={index}
              display="flex"
              gap="20px"
              width="100%"
              alignItems="center"
              sx={{ border: '2px solid ' + colors.secondary_background, padding: '20px', borderRadius: '10px' }}
            >
              <Typography flex={0.5}>{index + 1}</Typography>
              <Box flex={2}>
                <CustomSelectField
                  value={order.carrier_id}
                  options={[
                    ...user?.shipping_carriers,
                    // { carrier_name: SHIPPING_COMPANY.SINGAPORE_POST, id: SHIPPING_COMPANY_ID.SINGAPORE_POST },
                  ].map((carrier) => ({
                    key: carrier.carrier_name,
                    value: carrier.id + '',
                  }))}
                  label={'Carrier'}
                  fieldName={'carrier'}
                  handleChange={(e) => {
                    order.carrier_id = e.target.value;
                    updateOrdersInLocalStorage();
                  }}
                />
                <Typography></Typography>
              </Box>
              <Typography
                flex={0.8}
                fontSize="12px"
                fontWeight="bold"
                onClick={() => applyCarrierToAll(order.carrier_id)}
                sx={{
                  color: colors.accent,
                  marginLeft: '-10px',
                  cursor: 'pointer',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Apply to all
              </Typography>
              <Box flex={2}>
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index * 2] = el)}
                  type="text"
                  defaultValue={order.order_name}
                  onChange={(e) => {
                    order.order_name = e.target.value;
                    handleInputChange(index * 2, e.target.value);
                  }}
                  onFocus={() => handleFocus(index * 2)}
                  placeholder="Order No."
                  style={{
                    backgroundColor: colors.inputBackground,
                    border: 'none',
                    borderRadius: '10px',
                    padding: '15px 16px',
                    color: colors.text,
                    fontSize: '16px',
                  }}
                />
              </Box>
              <Box flex={2}>
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index * 2 + 1] = el)}
                  type="text"
                  defaultValue={order.tracking_number}
                  onChange={(e) => {
                    order.tracking_number = e.target.value;
                    handleInputChange(index * 2 + 1, e.target.value);
                  }}
                  onFocus={() => handleFocus(index * 2 + 1)}
                  placeholder="Tracking Number"
                  style={{
                    backgroundColor: colors.inputBackground,
                    border: 'none',
                    borderRadius: '10px',
                    padding: '15px 16px',
                    color: colors.text,
                    fontSize: '16px',
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          width="25%"
          display="flex"
          flexDirection="column"
          gap="20px"
          height="calc(100vh - 200px)"
          justifyContent="space-between"
          sx={{ position: 'sticky', top: '50px' }}
        >
          <Box>
            <RegularButton label="Clear" onClick={clearRows} variant="outlined" color="info" />
            <Box display="flex" alignItems="center" width="100%" gap="10px" mt="20px">
              <Typography>Add more rows:</Typography>
              <CustomTextField
                label="Row Count"
                fieldName="count"
                sx={{ width: '100px' }}
                value={newRowCount}
                type="number"
                handleChange={(event) => setNewRowCount(parseInt(event.target.value))}
              />
              <RegularButton label="Add" onClick={addNewRows} disabled={!newRowCount} />
            </Box>
          </Box>
          <RegularButton label="Mark as Ready To Ship" onClick={() => handleMarkAsReadyToShip()} loading={loading} />
        </Box>

        <CustomModal header={'Summary'} open={openSummaryModal} setOpen={setOpenSummaryModal} width={900}>
          {summary && (
            <>
              <Box display="flex" gap="20px" width="100%">
                <Box flex="1">
                  <Typography variant="h4" fontWeight="bold" color={colors.greenAccent}>
                    Total Valid Orders:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="30px" color={colors.greenAccent}>
                    {summary.shipped?.length}
                  </Typography>
                  <Box>
                    {summary.shipped?.map((order, index) => (
                      <Typography key={index}>
                        {order.order_name} - {order.tracking_number}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box flex="1">
                  <Typography variant="h4" fontWeight="bold" color={colors.redAccent}>
                    Total Orders With Errors:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="30px" color={colors.redAccent}>
                    {summary.not_shipped?.length}
                  </Typography>
                  <Box>
                    {summary.not_shipped?.map((order, index) => (
                      <Typography key={index}>
                        {order.order_name} - {order.error}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box width="100%" mt="20px">
                <Typography variant="h4" fontWeight="bold" color={colors.orangeAccent}>
                  Total Orders For Confirmation:
                </Typography>
                <Box display="flex" gap="20px" alignItems="center">
                  <Typography fontWeight="bold" fontSize="30px" color={colors.orangeAccent}>
                    {summary.for_confirmation?.length}
                  </Typography>
                  {!!summary.for_confirmation?.length && (
                    <RegularButton
                      variant="outlined"
                      color="warning"
                      size="small"
                      label="Proceed With Shipping"
                      onClick={() => handleMarkAsReadyToShip(true)}
                      loading={loading}
                    />
                  )}
                </Box>
                {summary.for_confirmation?.map((order, index) => (
                  <Typography key={index}>
                    {order.order_name} - {order.issue}
                  </Typography>
                ))}
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="center" mt="20px">
            <RegularButton
              label="Close and Clear"
              onClick={() => {
                setOpenSummaryModal(false);
                clearRows();
                clearOrdersInLocalStorage();
              }}
              variant="outlined"
              color="info"
            />
          </Box>
        </CustomModal>

        <CustomModal
          header={'Shipping Summary'}
          open={openShippingSummary}
          setOpen={setOpenShippingSummary}
          width={1000}
        >
          <CustomTable
            rows={shippingSummaryRows}
            rowCount={0}
            columns={shippingSummaryColumns}
            getData={setShippingSummary}
            loading={loadingTable}
            hideSearch
            rowId="date"
            hideFooterPagination
          />
        </CustomModal>

        <CustomModal
          header={'Ready To Ship Summary'}
          open={openReadyToShipSummary}
          setOpen={setOpenReadyToShipSummary}
          width={1400}
        >
          <CustomTable
            rows={readyToShipRows}
            rowCount={0}
            columns={readyToShipColumns}
            getData={setReadyToShipSummary}
            loading={loadingTable}
            hideSearch
            rowId="status"
            hideFooterPagination
          />
          <Divider sx={{ mb: '30px' }} />
          <OrdersTable status={ORDERS_TAB.FAILED_SHIPPING} />
        </CustomModal>

        <ConfirmationDialog
          title="Confirmation"
          open={openShipConfirmation}
          setOpen={setOpenShipConfirmation}
          message={`Are you sure you want to mark as shipped ${selectedCarrier.order_count} order${
            selectedCarrier.order_count > 1 ? 's' : ''
          } for ${selectedCarrier.carrier_name}?`}
          onConfirm={() =>
            markAsShipped(selectedCarrier.carrier_id).then(() => {
              enqueueSnackbar('Orders successfully marked as shipped.', { variant: 'success' });
              setReadyToShipSummary();
            })
          }
          loading={loadingShipConfirm}
          setLoading={setLoadingShipConfirm}
        />
      </Box>
    </Box>
  );
};

export default ShippingStation;

import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomIconButton } from 'components';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserContext } from 'context/user.context';
import { logout } from 'api/user';
import { tokens } from 'context/theme.context';

const Topbar = () => {
  const navigate = useNavigate();
  const colors = tokens();
  const { breadcrumb } = useContext(BreadcrumbContext);
  const { setUser } = useContext(UserContext);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const logoutUser = async () => {
    await logout();
    setUser(undefined);
    navigate('/login');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('authorities');
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="15px 30px"
        position="sticky"
        top="0"
        sx={{ backgroundColor: colors.background, zIndex: 2 }}
      >
        {/* BREADCRUMB */}
        <Box display="flex" alignItems="flex-start" gap={1}>
          <HomeIcon sx={{ color: colors.text }} />
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography
              variant="subtitle1"
              color={colors.text}
              sx={{
                cursor: 'pointer',
                borderBottom: `1px solid ${colors.text}`,
              }}
            >
              Home
            </Typography>
          </Link>
          {breadcrumb?.map((item, index) => {
            return (
              <Box display="flex" gap={1} key={index}>
                <Typography variant="subtitle1" color={colors.text}>
                  {'>'}
                </Typography>
                {item.link ? (
                  <Link to={item.link} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="subtitle1"
                      color={colors.text}
                      sx={
                        item.link
                          ? {
                              cursor: 'pointer',
                              borderBottom: `1px solid ${colors.text}`,
                            }
                          : {}
                      }
                    >
                      {item.label}
                    </Typography>
                  </Link>
                ) : (
                  <Typography variant="subtitle1" color={colors.text}>
                    {item.label}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>

        {/* ICONS */}
        <Box display="flex" gap={4}>
          <Box display="flex" gap={1} alignItems="center">
            <CustomIconButton
              icon={<LogoutIcon />}
              color={null}
              onClick={() => setOpenDeleteConfirmation(true)}
              tooltip="Logout"
              placement="bottom"
            />
          </Box>
        </Box>
      </Box>

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure you want to Logout?"
        onConfirm={async () => await logoutUser()}
      />
    </>
  );
};

export default Topbar;

import { Box, Typography } from '@mui/material';
import { formatDate, getTotalDaysFromDate } from 'utils';

import { ApiQuery } from 'model/interface';
import { CustomTable } from 'components';
import { getUnfulfilledInShopify } from 'api/order';
import { useState } from 'react';

type Props = {
  setTotalCount: (value: number) => void;
};

const UnfulfilledOrdersTable: React.FC<Props> = ({ setTotalCount }) => {
  const [unfulfilledOrders, setUnfulfilledOrders] = useState<any[]>([]);

  const getUnfulfilled = async (query: ApiQuery) => {
    const { data } = await getUnfulfilledInShopify(query);
    setUnfulfilledOrders(data.orders);
    setTotalCount(data.orders.length);
  };

  return (
    <Box>
      <CustomTable
        rows={unfulfilledOrders}
        searchKeys="Order No."
        columns={[
          {
            field: 'name',
            sortable: false,
            headerName: 'Order No.',
            flex: 1,
          },
          {
            field: 'created_at',
            headerName: 'Order Date',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => <Typography>{formatDate(value)}</Typography>,
          },
          {
            field: 'age',
            headerName: 'Days Unfulfilled',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => <Typography>{getTotalDaysFromDate(row.created_at)}</Typography>,
          },
          {
            field: 'line_items',
            headerName: 'SKU',
            flex: 1.5,
            sortable: false,
            renderCell: ({ row }) => (
              <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
                {row.line_items.map((item: any, index: number) => (
                  <Typography key={index}>{item.sku}</Typography>
                ))}
              </Box>
            ),
          },
          {
            field: 'shipping_country',
            headerName: 'Shipping Country',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => <Typography>{row.shipping_address?.country}</Typography>,
          },
          {
            field: 'tags',
            headerName: 'Tags',
            flex: 1,
            sortable: false,
          },
          {
            field: 'note',
            headerName: 'Notes',
            flex: 1,
            sortable: false,
          },
        ]}
        rowCount={0}
        getData={getUnfulfilled}
        headerComponent={
          <Typography>
            Note: WUDI is only showing a maximum of 100 orders, but there could be more orders that are still
            unfulfilled in Shopify.
          </Typography>
        }
      />
    </Box>
  );
};

export default UnfulfilledOrdersTable;
